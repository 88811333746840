import { makeAutoObservable } from 'mobx';
import { TGetTourResponse } from '@features/tour/types';

export class TourStore {
  tourDetail: TGetTourResponse = {} as TGetTourResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setTourDetail = (detail: TGetTourResponse) => {
    this.tourDetail = detail;
  };
}

const tourStore = new TourStore();

export default tourStore;
