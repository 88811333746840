import { makeAutoObservable } from 'mobx';
import { TSelectedPartnerAndService } from '@features/partners/types';

export class SelectedWebPartnerAndServiceStore {
  selectedWebPartnerAndService = {} as TSelectedPartnerAndService;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedWebPartnerAndService = (selectedWebPartnerAndService: TSelectedPartnerAndService) => {
    this.selectedWebPartnerAndService = selectedWebPartnerAndService;
  };

  resetSelectedWebPartnerAndService = () => {
    this.selectedWebPartnerAndService = {} as TSelectedPartnerAndService;
  };
}

const selectedWebPartnerAndServiceStore = new SelectedWebPartnerAndServiceStore();

export default selectedWebPartnerAndServiceStore;
