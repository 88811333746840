import { TAccountLoginPayload, TAccountLoginResponse, TCurrentAccount } from '@features/auth/types';
import httpClient from '@/utils/httpClient';
import { TGetServicesPayload, TPartnerServices } from '@features/webBooking/types';
import { TPartner } from '@features/webManagement/types';

const endpoint = '/Identity';

export const userLogin = (payload: TAccountLoginPayload) => {
  return httpClient<TAccountLoginResponse>({
    url: `${endpoint}/Authenticate`,
    method: 'post',
    data: payload,
  });
};

export const getCurrentUser = () => {
  return httpClient<TCurrentAccount>({
    url: `${endpoint}/Me`,
    method: 'get',
  });
};

export const getAccountPermissions = () => {
  return httpClient<string[]>({
    url: `${endpoint}/MyRights`,
    method: 'get',
  });
};

export const getPartners = () => {
  return httpClient<TPartner[]>({
    url: `${endpoint}/GetPartners`,
    method: 'get',
  });
};

export const getServices = (payload: TGetServicesPayload) =>
  httpClient<TPartnerServices[]>({
    url: `${endpoint}/GetServices`,
    method: 'get',
    params: payload,
  });
