import { useCallback } from 'react';
import { useStore } from '@/store';
import clsx from 'clsx';

export enum LOGO_SIZE {
  FULL = 'FULL',
  SMALL = 'SMALL',
}

const useGetAppSettings = () => {
  const {
    appSettingsStore: {
      appSettings: { appIcon, appLogo },
    },
  } = useStore();

  const setAppFavicon = useCallback(() => {
    if (!appIcon) return;

    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');

    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = appIcon;

    if (oldLink) {
      document.head.removeChild(oldLink);
    }

    document.head.appendChild(link);
  }, [appIcon]);

  const getAppLogo = useCallback(
    (size: LOGO_SIZE, customClassName?: string) => {
      if (!appLogo || (appLogo && !Object.keys(appLogo).length)) {
        return '';
      }
      return (
        <figure className={clsx('m-0', customClassName)}>
          <img
            alt="Logo"
            src={size === LOGO_SIZE.FULL ? appLogo.full : appLogo.icon}
            className="object-contain"
          />
        </figure>
      );
    },
    [appLogo]
  );

  return {
    setAppFavicon,
    getAppLogo,
  };
};

export default useGetAppSettings;
