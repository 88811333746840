import React, { useCallback, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { TAccountLoginPayload, TCurrentAccount } from '@features/auth/types';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import { getAccountPermissions, getCurrentUser, userLogin } from '@features/auth/services';
import { useStore } from '@/store';
import { getPath } from '@routes/router-paths';
import { FORM_FIELD, FormLabel, FormValidation } from './constants';

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const {
    accountInformationStore: { setAccountInformation, setMyPermission },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const navigatedFromUrl = location.state?.from;

  const handleLogin = useCallback(
    async (payload: TAccountLoginPayload) => {
      try {
        setIsLoading(true);
        const { accessToken } = await userLogin(payload);
        if (!accessToken) return;
        localStorage.setItem('accessToken', accessToken);
        const [getCurrentUserResponse, getAccountPermissionsResponse] = await Promise.allSettled([
          await getCurrentUser(),
          await getAccountPermissions(),
        ]);
        if (
          getCurrentUserResponse.status === 'fulfilled' &&
          getAccountPermissionsResponse.status === 'fulfilled'
        ) {
          setAccountInformation(getCurrentUserResponse.value as TCurrentAccount);
          setMyPermission(getAccountPermissionsResponse.value as string[]);
          history.push(
            (navigatedFromUrl !== getPath('login') && navigatedFromUrl) || getPath('portal')
          );
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [history, navigatedFromUrl, setAccountInformation, setMyPermission]
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleLogin} className="w-full">
      <Form.Item
        name={FORM_FIELD.PARTNER_CODE}
        label={FormLabel[FORM_FIELD.PARTNER_CODE]}
        rules={FormValidation[FORM_FIELD.PARTNER_CODE]}
      >
        <Input placeholder={FormLabel[FORM_FIELD.PARTNER_CODE]} size="large" />
      </Form.Item>

      <Form.Item
        name={FORM_FIELD.USERNAME}
        label={FormLabel[FORM_FIELD.USERNAME]}
        rules={FormValidation[FORM_FIELD.USERNAME]}
      >
        <Input placeholder={FormLabel[FORM_FIELD.USERNAME]} size="large" />
      </Form.Item>

      <Form.Item
        name={FORM_FIELD.PASSWORD}
        label={FormLabel[FORM_FIELD.PASSWORD]}
        rules={FormValidation[FORM_FIELD.PASSWORD]}
      >
        <Input.Password placeholder={FormLabel[FORM_FIELD.PASSWORD]} size="large" />
      </Form.Item>

      <Form.Item className="mb-0">
        <Button block type="primary" htmlType="submit" size="large" loading={isLoading}>
          Đăng nhập
        </Button>
      </Form.Item>
    </Form>
  );
};

export default observer(LoginForm);
