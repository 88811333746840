import { makeAutoObservable } from 'mobx';
import { TCountry } from '@features/webBooking/types';

export class CountryStore {
  countries: TCountry[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCountries = (countries: TCountry[]) => {
    this.countries = countries;
  };
}

const countryStore = new CountryStore();

export default countryStore;
