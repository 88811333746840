import type { Rule } from 'antd/es/form';

export enum FORM_FIELD {
  PARTNER_CODE = 'partnerCode',
  USERNAME = 'userName',
  PASSWORD = 'password',
}

export const FormLabel: Record<FORM_FIELD, string> = {
  [FORM_FIELD.PARTNER_CODE]: 'Mã đại lý',
  [FORM_FIELD.USERNAME]: 'Tên đăng nhập',
  [FORM_FIELD.PASSWORD]: 'Mật khẩu',
};

export const FormValidation: Record<FORM_FIELD, Rule[]> = {
  [FORM_FIELD.PARTNER_CODE]: [{ required: true }],
  [FORM_FIELD.USERNAME]: [{ required: true }],
  [FORM_FIELD.PASSWORD]: [{ required: true }],
};
