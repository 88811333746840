import { makeAutoObservable } from 'mobx';
import { TSite } from '@features/site/types';

export class SiteDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  sites: TSite[] = [];
  setSites = (sites: TSite[]) => {
    this.sites = sites;
  };
}

const siteDataStore = new SiteDataStore();

export default siteDataStore;
