import useGetAppSettings, { LOGO_SIZE } from '@/features/appSettings/hooks/useGetAppSettings';
import { useStore } from '@/store';
import AppHelmet from '@components/Helmet';
import LoginForm from '@features/auth/components/LoginForm';
import { getCurrentUser } from '@features/auth/services';
import AuthLayout from '@layouts/AuthLayout';
import { getPath } from '@routes/router-paths';
import { Button } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

const LoginPage = () => {
  const history = useHistory();

  const { getAppLogo } = useGetAppSettings();

  const {
    accountInformationStore: { setAccountInformation },
  } = useStore();

  const [alreadyOpened, setAlreadyOpened] = useState(false);

  const channel = useMemo(() => new BroadcastChannel('loginPage'), []);

  const checkIsUserLoggedIn = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return;
    const response = await getCurrentUser();
    if (!response?.userName) return;
    setAccountInformation(response);
    history.push(getPath('portal'));
  }, [history, setAccountInformation]);

  useEffect(() => {
    void checkIsUserLoggedIn();
  }, [checkIsUserLoggedIn]);

  useEffect(() => {
    channel.postMessage('anotherLoginPage');

    channel.addEventListener('message', msg => {
      console.log(msg);
      if (msg.data !== 'anotherLoginPage') return;
      setAlreadyOpened(true);
    });
  }, [channel]);

  const appLogo = getAppLogo(LOGO_SIZE.FULL, 'w-20 mb-5');

  if (alreadyOpened) {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center gap-2">
        {appLogo}
        <p className="text-center text-base">
          Trang đăng nhập của <strong className="text-base">Eco Manager App</strong> đang được mở ở
          tab khác
          <br />
          Nhấn "Tải lại trang" để thực hiện đăng nhập tại cửa sổ này
        </p>
        <Button type="primary" size="large" onClick={() => window.location.reload()}>
          Tải lại trang
        </Button>
      </div>
    );
  }

  return (
    <AuthLayout title="Đăng nhập" subTitle="Nhập thông tin chi tiết của bạn để tiếp tục">
      <AppHelmet title="Đăng nhập" />
      <LoginForm />
    </AuthLayout>
  );
};

export default LoginPage;
