export const ValidationRegex: Record<string, { schema: RegExp; message: string }> = {
  UserName: {
    schema: new RegExp(/^[A-Za-z][A-Za-z0-9_]{3,30}$/),
    message:
      'Tên đăng nhập dài từ 4 đến 31 kí tự bắt đầu bởi chữ cái, chỉ chấp nhận chữ thường, chữ viết hoa , số và dấu gạch dưới',
  },
  Email: {
    schema: new RegExp(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    ),
    message: 'Email sai định dạng',
  },
  DisplayName: {
    schema: new RegExp(/^([^\p{N}\p{S}\p{C}\p{P}]{2,50})$/u),
    message: 'Tên hiển thị từ 2 đến 50 kí tự và không có kí tự đặc biệt',
  },
  Password: {
    schema: new RegExp(/^(.?).{8,}$/),
    message: 'Mật khẩu tối thiểu 8 kí tự',
  },
  Phone: {
    schema: new RegExp(/^0\d{9,11}$/),
    message: 'Số điện thoại từ 10 đến 12 kí tự',
  },
  Domain: {
    schema: new RegExp(/^((w|W)eb|(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]+(\.[a-zA-Z]+)*)$/),
    message: 'Domain theo định dạng www.domain.com hoặc domain.com',
  },
  PartnerCode: {
    schema: new RegExp(/^[A-Za-z][A-Za-z\d]{1,4}$/),
    message: 'Mã đại lý chỉ chấp nhận chữ thường, chữ viết hoa và số, từ 2 đến 5 kí tự',
  },
  TaxCode: {
    schema: new RegExp(/^\d{10}(?:-\d{3})?$/),
    message: 'Sai định dạng mã số thuế',
  },
  BookingCode: {
    schema: new RegExp(/^([A-Za-z0-9]{6}|(738|926)\d{10})$/),
    message: 'Sai định dạng Mã đặt chỗ/PNR',
  },
  AirlineCode: {
    schema: new RegExp(/^[A-Za-z0-9]{2}$/),
    message: 'Mã hãng hàng không gồm 2 ký tự chữ cái, chữ số.',
  },
  VirtualAccountNamePaymentNarrative: {
    schema: new RegExp(/^[A-Za-z0-9\s]{3,50}$/),
    message: 'Chữ không dấu, không có ký tự đặc biệt và dài từ 3 đến 50 ký tự',
  },
  PassengerName: {
    schema: new RegExp(/^[\p{L}\s]+ [\p{L}\s]+$/u),
    message: 'Vui lòng ghi rõ họ tên',
  },
  FlightNumber: {
    schema: new RegExp(/^[A-Za-z0-9]{2}\d{2,4}$/),
    message: 'Sai định dạng số hiệu chuyến bay',
  },
  FlightPoint: {
    schema: new RegExp(/^[A-Za-z]{3}$/),
    message: 'Sai định dạng',
  },
  Pcc: {
    schema: new RegExp(/^[A-Za-z0-9]{3,5}$/),
    message: 'PCC có độ dài 3-5 ký tự và chỉ bao gồm chữ cái, chữ số.',
  },
  NationalityCountryCode: {
    schema: new RegExp(/^[A-Za-z]{2}[A-Za-z]$/),
    message: 'Sai định dạng mã',
  },
  DocumentNumber: {
    schema: new RegExp(/^[A-Za-z0-9]{6,9}$/),
    message: 'Sai định dạng số hộ chiếu',
  },
  PassportIdentificationNumber: {
    schema: new RegExp(/^[a-zA-Z0-9]+$/),
    message: 'Sai định dạng giấy tờ',
  },
  Number: {
    schema: new RegExp(/^[0-9]+$/),
    message: 'Vui lòng chỉ nhập số.',
  },
  DateString: {
    schema: new RegExp(/\d{2}\/\d{2}\/\d{4}/),
    message: 'Định dạng DD/MM/YYYY',
  },
  TextWithPassengerTitle: {
    schema: new RegExp(/^(MRS|MSTR|MR|MS|MISS)\s(.*)$/),
    message: 'Sai định dạng danh xưng',
  },
  PassengerTitle: {
    schema: new RegExp(/(?=MRS|MSTR|MR|MS|MISS)/),
    message: 'Sai định dạng danh xưng',
  },
  NewLine: {
    schema: new RegExp(/\r\n|\r|\n\n/g),
    message: 'Sai định dạng xuống dòng',
  },
} as const;

export enum CARD_TYPE {
  VISA = 'VISA',
  MASTER_CARD = 'MASTER_CARD',
  DISCOVER = 'DISCOVER',
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DINERS_CLUB = 'DINERS_CLUB',
  JCB = 'JCB',
  MAESTRO = 'MAESTRO',
  LASER = 'LASER',
  UNION_PAY = 'UNION_PAY',
  ELO = 'ELO',
  HIPERCARD = 'HIPERCARD',
  TROY = 'TROY',
}

export const CreditCardRegex = {
  [CARD_TYPE.VISA]: /^(4[0-9]{12}(?:[0-9]{3})?)$/, // Visa (13 or 16 digits starting with 4)
  [CARD_TYPE.MASTER_CARD]: /^(5[1-5][0-9]{14})$/, // MasterCard (16 digits starting with 51-55)
  [CARD_TYPE.DISCOVER]: /^(6(?:011|5[0-9]{2})[0-9]{12})$/, // Discover (16 digits starting with 6011 or 5)
  [CARD_TYPE.AMERICAN_EXPRESS]: /^(3[47][0-9]{13})$/, // American Express (15 digits starting with 34 or 37)
  [CARD_TYPE.DINERS_CLUB]: /^(3(?:0[0-5]|[68][0-9])[0-9]{11})$/, // Diners Club (14 digits starting with 300-305, 36, or 38)
  [CARD_TYPE.JCB]: /^((?:2131|1800|35\d{3})[0-9]{11})$/, // JCB (16 digits starting with specific prefixes)
  [CARD_TYPE.MAESTRO]: /^(5[0678])(|\d{2})[0-9]{11}$/, // Maestro (12-19 digits starting with 50, 56, 57, 58)
  [CARD_TYPE.LASER]: /^(630)[0-9]{12}$/, // Laser (15 digits starting with 630)
  [CARD_TYPE.UNION_PAY]: /^(62)[0-9]{14,17}$/, // UnionPay (16-19 digits starting with 62)
  [CARD_TYPE.ELO]: /^(4[0-9]{15}(?:[0-9]{3})?)$/, // Elo (16 or 19 digits starting with 4)
  [CARD_TYPE.HIPERCARD]: /^(45)[0-9]{14,17}$/, // Hipercard (16-19 digits starting with 45)
  [CARD_TYPE.TROY]: /^(7599)[0-9]{13}$/, // Troy (15 digits starting with 7599)
} as const;
