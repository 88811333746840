import {
  TAmusementParkRateSelected,
  TAmusementParkTicketSearch,
  TTicket,
} from '@features/amusementParkTicket/types';
import { makeAutoObservable } from 'mobx';

export class AmusementParkTicketDataStore {
  amusementParkTicketData: TAmusementParkTicketSearch = {} as TAmusementParkTicketSearch;
  amusementParkTicketSelected: TTicket = {} as TTicket;
  amusementParkRatesSelected: TAmusementParkRateSelected[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAmusementParkTicketData = (amusementParkTicketData: TAmusementParkTicketSearch) => {
    this.amusementParkTicketData = amusementParkTicketData;
  };

  setAmusementParkTicketSelected = (ticket: TTicket) => {
    this.amusementParkTicketSelected = ticket;
  };

  setAmusementParkRatesSelected = (rates: TAmusementParkRateSelected[]) => {
    this.amusementParkRatesSelected = rates;
  };
}

const amusementParkTicketDataStore = new AmusementParkTicketDataStore();

export default amusementParkTicketDataStore;
