import config from '@/config';
import { useStore } from '@/store';
import ErrorBoundaries from '@components/ErrorBoundaries/ErrorBoundaries';
import ScrollRestoration from '@components/ScrollRestoration';
import SplashScreen from '@components/SplashScreen';
import { getAccountPermissions, getCurrentUser, getServices } from '@features/auth/services';
import AppRoutes from '@routes/AppRoutes';
import 'styles/global.scss';
import { getPath } from '@routes/router-paths';
import { APIProvider } from '@vis.gl/react-google-maps';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/locale/vi_VN';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import updateLocale from 'dayjs/plugin/updateLocale';
import { observer } from 'mobx-react-lite';
import React, { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import useGetAppSettings from '@features/appSettings/hooks/useGetAppSettings';
import { getAppSettingsQuery } from '@features/appSettings/services';
import { LOCAL_STORAGE_APP_VERSION_KEY } from '@/utils/constants';
import OldVersionWarningModal from '@features/appSettings/components/OldVersionWarningModal/OldVersionWarningModal';

dayjs.locale('vi');
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.updateLocale('vi', {
  weekStart: 1,
});

/** Expose dayjs to window object for logging purpose */
// @ts-ignore
window.dayjs = dayjs;

const App: FC = () => {
  const {
    commonStore: { appTheme },
    accountInformationStore: { accountInformation, setAccountInformation, setMyPermission },
    serviceDataStore: { setPartnerServices },
    appSettingsStore: { appSettings, setAppSettings },
  } = useStore();

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isLoadSettingsFailed, setIsLoadSettingsFailed] = useState(false);

  const { setAppFavicon } = useGetAppSettings();

  const onGetAppSettings = useCallback(async () => {
    try {
      const res = await getAppSettingsQuery();
      setAppSettings(res);
    } catch (e) {
      setIsLoadSettingsFailed(true);
    }
  }, [setAppSettings]);

  const handleGetCurrentUser = useCallback(async () => {
    const response = await getCurrentUser();
    setAccountInformation(response);
  }, [setAccountInformation]);

  const getPermissions = useCallback(async () => {
    const response = await getAccountPermissions();
    setMyPermission(response);
  }, [setMyPermission]);

  const handleGetServices = useCallback(
    async (partnerCode: string) => {
      const response = await getServices({ partnerCode, includeDeposit: true });
      setPartnerServices(response);
    },
    [setPartnerServices]
  );

  const shouldCheckUserIdentity = useMemo(
    () => localStorage.getItem('accessToken') && window.location.pathname !== getPath('login'),
    []
  );

  useEffect(() => {
    if (shouldCheckUserIdentity) {
      void handleGetCurrentUser();
    }
  }, [handleGetCurrentUser, shouldCheckUserIdentity]);

  useEffect(() => {
    const { partnerCode } = accountInformation;
    if (partnerCode) {
      void handleGetServices(partnerCode);
    }
  }, [accountInformation, handleGetServices]);

  useEffect(() => {
    if (!shouldCheckUserIdentity) return;
    void getPermissions();
  }, [getPermissions, shouldCheckUserIdentity]);

  useEffect(() => {
    void onGetAppSettings();
  }, [onGetAppSettings]);

  useEffect(() => {
    const { appVersion } = appSettings;
    const currentVersion = localStorage.getItem(LOCAL_STORAGE_APP_VERSION_KEY) || '';
    if (!appVersion) return;
    if (!currentVersion) {
      localStorage.setItem(LOCAL_STORAGE_APP_VERSION_KEY, appVersion);
      return;
    }

    currentVersion !== appVersion && setIsOpenWarningModal(true);
  }, [appSettings]);

  useEffect(() => {
    setAppFavicon();
  }, [setAppFavicon]);

  if (isLoadSettingsFailed) {
    throw new Error('Get app settings failed!');
  }

  return (
    <Suspense
      fallback={
        <ScrollRestoration>
          <SplashScreen fullscreen />
        </ScrollRestoration>
      }
    >
      <APIProvider apiKey={config.apiKeyGoogleMap || ''}>
        <ConfigProvider
          locale={vi_VN}
          theme={{
            token: {
              colorPrimary: appTheme,
              borderRadius: 5,
            },
          }}
        >
          <ErrorBoundaries>
            <AppRoutes />
            <OldVersionWarningModal isOpen={isOpenWarningModal} setIsOpen={setIsOpenWarningModal} />
          </ErrorBoundaries>
        </ConfigProvider>
      </APIProvider>
    </Suspense>
  );
};

export default observer(App);
